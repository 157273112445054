import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				pageData: wpPage(slug: { eq: "homepage" }) {
					seoFields {
						localBusinessSchema
					}
				}
			}
		`
	);

	// Log the schema string to inspect it
	const schemaString = data.pageData.seoFields.localBusinessSchema;
	// Parse schema string to JSON
	let localBusinessSchema;
	try {
		localBusinessSchema = JSON.parse(schemaString);
	} catch (e) {
		console.error("Failed to parse local business schema", e);
		return null;
	}

	// Ensure aggregateRating exists before setting reviewCount

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
